var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "game-feed", attrs: { "ma-4": "", "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs3: "", "mx-2": "" } }, [
                _c("h2", [_vm._v("Search Instructions")])
              ]),
              _c(
                "v-flex",
                {
                  staticClass: "game-feed-description",
                  attrs: { xs3: "", "mx-2": "" }
                },
                [
                  _c("v-text-field", {
                    attrs: { label: "Missions Instrucitons to match in part" },
                    model: {
                      value: _vm.searchText,
                      callback: function($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs3: "", "mx-2": "" } }, [
                _c("h2", [_vm._v("Replace With")])
              ]),
              _c(
                "v-flex",
                {
                  staticClass: "game-feed-description",
                  attrs: { xs3: "", "mx-2": "" }
                },
                [
                  _c("v-text-field", {
                    attrs: { label: "Replace with" },
                    model: {
                      value: _vm.replaceText,
                      callback: function($$v) {
                        _vm.replaceText = $$v
                      },
                      expression: "replaceText"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "game-feed-description",
                  attrs: { xs3: "", "mx-2": "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "red",
                      attrs: { loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.searchAndReplace()
                        }
                      }
                    },
                    [_vm._v("Replace ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { column: "" } },
            _vm._l(_vm.results, function(item, i) {
              return _c("v-flex", { key: i, attrs: { xs12: "", "mx-2": "" } }, [
                _c(
                  "b",
                  {
                    on: {
                      click: function($event) {
                        return _vm.edit(item)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(i + 1) +
                        ". GAME: " +
                        _vm._s(item.game.name) +
                        '" ' +
                        _vm._s(item.name) +
                        " - " +
                        _vm._s(item.title) +
                        " "
                    )
                  ]
                ),
                _vm._v(" - " + _vm._s(_vm.showSearch(item.instructions)) + " ")
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "game-settings-wrap",
          attrs: { width: "80%" },
          model: {
            value: _vm.editMission,
            callback: function($$v) {
              _vm.editMission = $$v
            },
            expression: "editMission"
          }
        },
        [
          _c("MissionEdit", {
            attrs: { mission: _vm.mission },
            on: {
              closeMissionEdit: function($event) {
                return _vm.close()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }