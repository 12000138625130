var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "highlight", dark: "" } },
            [
              _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
              _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.mission.name))]),
              _c("v-spacer"),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.update } },
                [_c("v-icon", [_vm._v("saves")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("cancel")])],
                1
              )
            ],
            1
          ),
          _vm.mission
            ? _c(
                "div",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Name", required: "" },
                            model: {
                              value: _vm.mission.name,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "name", $$v)
                              },
                              expression: "mission.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Title", required: "" },
                            model: {
                              value: _vm.mission.title,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "title", $$v)
                              },
                              expression: "mission.title"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm1: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Points", required: "" },
                            model: {
                              value: _vm.mission.points,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "points", $$v)
                              },
                              expression: "mission.points"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "instruction",
                          attrs: { xs12: "", "mx-2": "" }
                        },
                        [
                          _c("v-textarea", {
                            attrs: {
                              name: "instructions",
                              rows: "3",
                              label: "Instructions",
                              required: ""
                            },
                            model: {
                              value: _vm.mission.instructions,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "instructions", $$v)
                              },
                              expression: "mission.instructions"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticStyle: { "margin-top": "-50px" },
                          attrs: { xs6: "", "mx-2": "" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Notes", notes: "" },
                            model: {
                              value: _vm.mission.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "notes", $$v)
                              },
                              expression: "mission.notes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticStyle: { "margin-top": "-50px" },
                          attrs: { xs2: "" }
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Pass Scribe" },
                            model: {
                              value: _vm.mission.passScribe,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "passScribe", $$v)
                              },
                              expression: "mission.passScribe"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticStyle: { "margin-top": "-50px" },
                          attrs: { xs2: "" }
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "No Vote" },
                            model: {
                              value: _vm.mission.noVote,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "noVote", $$v)
                              },
                              expression: "mission.noVote"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.behaviors,
                              label: "Behaviors",
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.defaultModes($event)
                              }
                            },
                            model: {
                              value: _vm.mission.behavior,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "behavior", $$v)
                              },
                              expression: "mission.behavior"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm1: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Time Limit (seconds)",
                              required: ""
                            },
                            model: {
                              value: _vm.mission.time,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "time", $$v)
                              },
                              expression: "mission.time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mx-2": "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.playTypes,
                              label: "Play Types",
                              required: ""
                            },
                            model: {
                              value: _vm.mission.playType,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "playType", $$v)
                              },
                              expression: "mission.playType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                        [
                          _c("v-select", {
                            attrs: { items: _vm.tries, label: "Max Tries" },
                            model: {
                              value: _vm.mission.numOfTries,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "numOfTries", $$v)
                              },
                              expression: "mission.numOfTries"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.mission.behavior == "Fact Match"
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "mx-5": "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.freeFormMissions,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Connect to Free Form mission"
                                },
                                model: {
                                  value: _vm.mission.freeFormMissionID,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.mission,
                                      "freeFormMissionID",
                                      $$v
                                    )
                                  },
                                  expression: "mission.freeFormMissionID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mission.behavior === "Link"
                        ? _c(
                            "v-flex",
                            { attrs: { xs8: "", "mx-2": "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Link URL" },
                                model: {
                                  value: _vm.mission.answer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.mission, "answer", $$v)
                                  },
                                  expression: "mission.answer"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mission.behavior === "Link"
                        ? _c(
                            "v-flex",
                            {
                              staticStyle: { "margin-top": "-5px" },
                              attrs: { xs3: "" }
                            },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Show Winning Team" },
                                model: {
                                  value: _vm.mission.showWinningTeam,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.mission,
                                      "showWinningTeam",
                                      $$v
                                    )
                                  },
                                  expression: "mission.showWinningTeam"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mx-2": "" } },
                            [
                              _vm.mission.behavior === "Text" ||
                              _vm.mission.behavior === "Draw: Pictionary" ||
                              _vm.mission.behavior === "Categories" ||
                              _vm.mission.behavior === "Lipdub"
                                ? _c("v-text-field", {
                                    attrs: { label: "Answer" },
                                    model: {
                                      value: _vm.mission.answer,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "answer", $$v)
                                      },
                                      expression: "mission.answer"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.mission.behavior === "Multiple Choice" ||
                      _vm.mission.behavior === "Poll"
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "mx-2": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _vm.mission.behavior === "Multiple Choice"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs10: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Answer 1" },
                                            model: {
                                              value: _vm.mission.answer1,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer1",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer1"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Answer 2" },
                                            model: {
                                              value: _vm.mission.answer2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer2",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer2"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Answer 3" },
                                            model: {
                                              value: _vm.mission.answer3,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer3",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer3"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Answer 4" },
                                            model: {
                                              value: _vm.mission.answer4,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer4",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer4"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Answer 5" },
                                            model: {
                                              value: _vm.mission.answer5,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer5",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer5"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-flex",
                                        { attrs: { xs10: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Option 1" },
                                            model: {
                                              value: _vm.mission.answer1,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer1",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer1"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Option 2" },
                                            model: {
                                              value: _vm.mission.answer2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer2",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer2"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Option 3" },
                                            model: {
                                              value: _vm.mission.answer3,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer3",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer3"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Option 4" },
                                            model: {
                                              value: _vm.mission.answer4,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer4",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer4"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: { label: "Option 5" },
                                            model: {
                                              value: _vm.mission.answer5,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer5",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer5"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                  _vm.mission.behavior === "Multiple Choice"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs2: "", "mx-3": "" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              model: {
                                                value: _vm.mission.multiCorrect,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mission,
                                                    "multiCorrect",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "mission.multiCorrect"
                                              }
                                            },
                                            _vm._l(5, function(n) {
                                              return _c("v-radio", {
                                                key: n,
                                                staticClass: "correct",
                                                attrs: {
                                                  value: n,
                                                  "my-5": "",
                                                  label: "correct"
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "YouTube Link" },
                            model: {
                              value: _vm.mission.youtube,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "youtube", $$v)
                              },
                              expression: "mission.youtube"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "", "mx-2": "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Start time (secs)" },
                            model: {
                              value: _vm.mission.videoTime,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "videoTime", $$v)
                              },
                              expression: "mission.videoTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "", "mx-2": "" } },
                        [
                          _c("image-uploader", {
                            attrs: {
                              debug: 1,
                              "max-width": 1200,
                              quality: 1,
                              "auto-rotate": true,
                              preview: false,
                              "class-name": [
                                "fileinput",
                                { "fileinput--loaded": _vm.mission.photo }
                              ],
                              "output-format": "verbose",
                              capture: "environment",
                              accept: ".gif,.jpg,.png,.jpeg"
                            },
                            on: {
                              input: function($event) {
                                return _vm.setImage($event)
                              }
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Photo URL" },
                            model: {
                              value: _vm.mission.photo,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "photo", $$v)
                              },
                              expression: "mission.photo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "", "mx-2": "" } },
                        [
                          _c("image-uploader", {
                            attrs: {
                              debug: 1,
                              "max-width": 600,
                              quality: 1,
                              "auto-rotate": true,
                              preview: false,
                              "class-name": [
                                "fileinput",
                                {
                                  "fileinput--loaded":
                                    _vm.mission.instructionsPhoto
                                }
                              ],
                              "output-format": "verbose",
                              capture: "environment",
                              accept: ".gif,.jpg,.png,.jpeg"
                            },
                            on: {
                              input: function($event) {
                                return _vm.setInstructionsImage($event)
                              }
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Instructions Image or Gif" },
                            model: {
                              value: _vm.mission.instructionsPhoto,
                              callback: function($$v) {
                                _vm.$set(_vm.mission, "instructionsPhoto", $$v)
                              },
                              expression: "mission.instructionsPhoto"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _vm.copying
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { flat: "" },
                      on: { click: _vm.update }
                    },
                    [_vm._v("Copy")]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { flat: "", loading: _vm.loadingImage },
                      on: { click: _vm.update }
                    },
                    [_vm._v("Save")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "secondary",
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }