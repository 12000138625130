<template>
  <v-card>
    <v-card-text>
      <v-toolbar color="highlight" dark>
        <v-icon dark>edit</v-icon>
        <v-toolbar-title> {{ mission.name }}</v-toolbar-title>
        <v-spacer />
        <v-spacer />
        <v-btn icon @click="update">
          <v-icon>saves</v-icon>
        </v-btn>
        <v-btn icon @click="close()">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="mission">
        <v-layout row wrap>
          <v-flex xs12 sm5 mx-2>
            <v-text-field v-model="mission.name" label="Name" required />
          </v-flex>
          <v-flex xs12 sm5 mx-2>
            <v-text-field v-model="mission.title" label="Title" required />
          </v-flex>
          <v-flex xs12 sm1 mx-2>
            <v-text-field v-model="mission.points" label="Points" required />
          </v-flex>

          <v-flex xs12 mx-2 class="instruction">
            <v-textarea
              v-model="mission.instructions"
              name="instructions"
              rows="3"
              label="Instructions"
              required
            />
          </v-flex>
          <v-flex xs6 mx-2 style="margin-top: -50px">
            <v-text-field v-model="mission.notes" label="Notes" notes />
          </v-flex>
          <v-flex xs2 style="margin-top: -50px">
            <v-checkbox v-model="mission.passScribe" label="Pass Scribe" />
          </v-flex>
          <v-flex xs2 style="margin-top: -50px">
            <v-checkbox v-model="mission.noVote" label="No Vote" />
          </v-flex>

          <v-flex xs12 sm3 mx-2>
            <v-select
              :items="behaviors"
              v-model="mission.behavior"
              @change="defaultModes($event)"
              label="Behaviors"
              required
            />
          </v-flex>
          <v-flex xs12 sm1 mx-2>
            <v-text-field
              v-model="mission.time"
              label="Time Limit (seconds)"
              required
            />
          </v-flex>
          <v-flex xs12 sm4 mx-2>
            <v-select
              :items="playTypes"
              v-model="mission.playType"
              label="Play Types"
              required
            />
          </v-flex>
          <v-flex xs12 sm2 mx-2>
            <v-select
              :items="tries"
              v-model="mission.numOfTries"
              label="Max Tries"
            />
          </v-flex>
          <v-flex xs12 mx-5 v-if="mission.behavior == 'Fact Match'">
            <v-select
              :items="freeFormMissions"
              item-text="name"
              item-value="id"
              v-model="mission.freeFormMissionID"
              label="Connect to Free Form mission"
            />
          </v-flex>

          <v-flex xs8 mx-2 v-if="mission.behavior === 'Link'">
            <v-text-field v-model="mission.answer" label="Link URL" />
          </v-flex>
          <v-flex
            xs3
            style="margin-top: -5px"
            v-if="mission.behavior === 'Link'"
          >
            <v-checkbox
              v-model="mission.showWinningTeam"
              label="Show Winning Team"
            />
          </v-flex>

          <transition name="fade">
            <v-flex xs12 mx-2>
              <v-text-field
                v-if="
                  mission.behavior === 'Text' ||
                  mission.behavior === 'Draw: Pictionary' ||
                  mission.behavior === 'Categories' ||
                  mission.behavior === 'Lipdub'
                "
                v-model="mission.answer"
                label="Answer"
              />
            </v-flex>
          </transition>

          <v-flex
            v-if="
              mission.behavior === 'Multiple Choice' ||
              mission.behavior === 'Poll'
            "
            xs12
            mx-2
          >
            <v-layout row>
              <v-flex v-if="mission.behavior === 'Multiple Choice'" xs10>
                <v-text-field v-model="mission.answer1" label="Answer 1" />
                <v-text-field v-model="mission.answer2" label="Answer 2" />
                <v-text-field v-model="mission.answer3" label="Answer 3" />
                <v-text-field v-model="mission.answer4" label="Answer 4" />
                <v-text-field v-model="mission.answer5" label="Answer 5" />
              </v-flex>
              <v-flex v-else xs10>
                <v-text-field v-model="mission.answer1" label="Option 1" />
                <v-text-field v-model="mission.answer2" label="Option 2" />
                <v-text-field v-model="mission.answer3" label="Option 3" />
                <v-text-field v-model="mission.answer4" label="Option 4" />
                <v-text-field v-model="mission.answer5" label="Option 5" />
              </v-flex>
              <v-flex v-if="mission.behavior === 'Multiple Choice'" xs2 mx-3>
                <v-radio-group v-model="mission.multiCorrect">
                  <v-radio
                    v-for="n in 5"
                    :key="n"
                    :value="n"
                    my-5
                    class="correct"
                    label="correct"
                  />
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs2 mx-2>
            <v-text-field v-model="mission.youtube" label="YouTube Link" />
          </v-flex>
          <v-flex xs3 mx-2>
            <v-text-field
              v-model="mission.videoTime"
              label="Start time (secs)"
            />
          </v-flex>
          <v-flex xs3 mx-2>
            <image-uploader
              :debug="1"
              :max-width="1200"
              :quality="1"
              :auto-rotate="true"
              :preview="false"
              :class-name="[
                'fileinput',
                { 'fileinput--loaded': mission.photo }
              ]"
              output-format="verbose"
              capture="environment"
              accept=".gif,.jpg,.png,.jpeg"
              @input="setImage($event)"
            >
            </image-uploader>
            <v-text-field v-model="mission.photo" label="Photo URL" />
          </v-flex>
          <v-flex xs3 mx-2>
            <image-uploader
              :debug="1"
              :max-width="600"
              :quality="1"
              :auto-rotate="true"
              :preview="false"
              :class-name="[
                'fileinput',
                { 'fileinput--loaded': mission.instructionsPhoto }
              ]"
              output-format="verbose"
              capture="environment"
              accept=".gif,.jpg,.png,.jpeg"
              @input="setInstructionsImage($event)"
            >
            </image-uploader>
            <v-text-field
              v-model="mission.instructionsPhoto"
              label="Instructions Image or Gif"
            />
          </v-flex>
        </v-layout>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-center>
        <v-btn v-if="copying" flat class="primary" @click="update">Copy</v-btn>
        <v-btn
          v-else
          flat
          class="primary"
          :loading="loadingImage"
          @click="update"
          >Save</v-btn
        >
        <v-btn flat class="secondary" @click="close()">Cancel</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import { mapGetters } from "vuex"
import uniqid from "uniqid"
export default {
  name: "MissionEdit",
  props: {
    mission: {
      type: Object,
      required: true
    },
    copying: {
      type: Boolean,
      required: false
    },
    adding: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      theMission: {},
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      tmp: "",
      search: "",
      pagination: {},
      behavior: "",
      loadingImage: false,
      behaviors: this.$store.getters.behaviors,
      playTypes: this.$store.getters.playTypes,
      playType: "Team: Speed Matters"
    }
  },
  computed: {
    ...mapGetters("auth", ["token"])
  },
  methods: {
    async setImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.mission.name}.png`
        this.mission.photo = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.mission.photo)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async setInstructionsImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.mission.name}.png`
        this.mission.instructionsPhoto = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.mission.instructionsPhoto)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    deleteMission() {
      console.log(this.mission)
      this.$store.dispatch("removeMission", this.mission)
      this.$emit("closeMissionEdit")
    },
    close() {
      console.log(this.mission)
      this.$emit("closeMissionEdit")
    },
    update() {
      this.$store.dispatch("updateMission", this.mission)
      this.$emit("closeMissionEdit")
    }
  }
}
</script>
